
















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import camelcaseKeys from 'camelcase-keys';
import { constants } from '../../../utils/constants';

import ManageDocumentSeriesModal from './components/ManageDocumentSeriesModal/ManageDocumentSeriesModal.vue';
import DocumentSeriesTable from './DocumentSeriesTable.vue';

import {
  addDocumentSeries,
  editDocumentSeries,
  removeDocumentSeries,
  getAvailableDocumentSeries,
  toggleDocumentSeriesActive,
} from './document-series-management-api';

@Component({
  components: {
    ManageDocumentSeriesModal,
    DocumentSeriesTable,
  },
})
export default class extends Vue {
  grnTableTitle: string ='Serii NIR-uri';
  mrnTableTitle: string ='Serii bonuri de consum';
  bcoTableTitle: string ='Serii bonuri consum obiecte inventar';
  btoTableTitle: string ='Serii bonuri transfer obiecte inventar';

  grnDocumentTypeName: string ='NIR';
  mrnDocumentTypeName: string ='Bon de consum';
  bcoDocumentTypeName: string ='Bon consum obiecte inventar';
  btoDocumentTypeName: string ='Bon transfer obiecte inventar';

  grnTabTitle: string = 'NIR';
  mrnTabTitle: string = 'Bon consum';
  bcoTabTitle: string = 'Bon consum obiecte inventar';
  btoTabTitle: string = 'Bon transfer obiecte inventar';

  grnSeries: Array<any> = [];
  mrnSeries: Array<any> = [];
  bcoSeries: Array<any> = [];
  btoSeries: Array<any> = [];

  grnSeriesYear = null;
  mrnSeriesYear = null;
  bcoSeriesYear = null;
  btoSeriesYear = null;

  grnAvailableYears: Array<any> = [];
  mrnAvailableYears: Array<any> = [];
  bcoAvailableYears: Array<any> = [];
  btoAvailableYears: Array<any> = [];

  grnPageParams = { pageNumber: 1, totalRows: 0 };
  mrnPageParams = { pageNumber: 1, totalRows: 0 };
  bcoPageParams = { pageNumber: 1, totalRows: 0 };
  btoPageParams = { pageNumber: 1, totalRows: 0 };

  generalPerPage = 15;

  tableBusy = false;
  documentConstants = constants;

  async disableSeries({ documentSeriesId }) {
    try {
      await toggleDocumentSeriesActive(documentSeriesId, false);
      this.$root.$bvToast.toast('Starea a fost schimbată cu succes', { variant: 'success', solid: true, title: 'Succes' });
    } catch (err) {
      this.$root.$bvToast.toast('Eroare la schimbarea stării', { variant: 'danger', solid: true, title: 'Eroare' });
    } finally {
      await this.loadGridPage();
    }
  }

  async enableSeries({ documentSeriesId }) {
    try {
      await toggleDocumentSeriesActive(documentSeriesId, true);
      this.$root.$bvToast.toast('Starea a fost schimbată cu succes', { variant: 'success', solid: true, title: 'Succes' });
    } catch (err) {
      this.$root.$bvToast.toast('Eroare la schimbarea stării', { variant: 'danger', solid: true, title: 'Eroare' });
    } finally {
      await this.loadGridPage();
    }
  }

  async removeSeries({ documentType, documentSeriesId }) {
    if (!(await this.$bvModal.msgBoxConfirm('Confirmi eliminarea acestei serii?'))) {
      return;
    }

    try {
      const response = await removeDocumentSeries(documentType, documentSeriesId);
      if (!response.isSuccesful) {
        this.$root.$bvToast.toast(response.message, { variant: 'danger', solid: true, title: 'Eroare' });
        return;
      }
    } catch (err) {
      this.$root.$bvToast.toast('Eroare la eliminarea seriei', { variant: 'danger', solid: true, title: 'Eroare' });
    } finally {
      await this.loadGridPage();
    }
  }

  async onCurrentGridPageChanged({ page, type }) {
    if (type === this.documentConstants.GRN) {
      this.grnPageParams.pageNumber = page;
      await this.loadGrnSeries();
    } else if (type === this.documentConstants.MRN) {
      this.mrnPageParams.pageNumber = page;
      await this.loadMrnSeries();
    } else if (type === this.documentConstants.BCO) {
      this.bcoPageParams.pageNumber = page;
      await this.loadBcoSeries();
    } else if (type === this.documentConstants.BTO) {
      this.btoPageParams.pageNumber = page;
      await this.loadBtoSeries();
    }
  }

  async onSeriesYearSelect({ value, type }) {
    this.resetPageParams(type);
    if (type === this.documentConstants.GRN) {
      this.grnSeriesYear = value;
      await this.loadGrnSeries();
    } else if (type === this.documentConstants.MRN) {
      this.mrnSeriesYear = value;
      await this.loadMrnSeries();
    } else if (type === this.documentConstants.BCO) {
      this.bcoSeriesYear = value;
      await this.loadBcoSeries();
    } else if (type === this.documentConstants.BTO) {
      this.btoSeriesYear = value;
      await this.loadBtoSeries();
    }
  }

  async onApplyItemsPerPage({ value }) {
    this.generalPerPage = value;
    await this.loadGridPage();
  }

  onShowAddEdit({
    documentType, actionType, documentSeriesId, data,
  }) {
    (<any>(this.$refs.addEditModal)).showModal({
      documentType, actionType, documentSeriesId, data,
    });
  }

  async onAddEditModalOk(payload: { actionType: string; }) {
    try {
      if (payload.actionType === 'add') {
        await addDocumentSeries(payload);
      } else {
        await editDocumentSeries(payload);
      }
    } catch (err : any) {
      this.$bvModal.msgBoxOk(<string>((<any>err)?.response?.data?.errorMessage));
    } finally {
      await this.loadGridPage();
    }
  }

  async loadGridPage() {
    try {
      this.tableBusy = true;
      await Promise.allSettled([
        this.loadGrnSeries(),
        this.loadMrnSeries(),
        this.loadBcoSeries(),
        this.loadBtoSeries(),
      ]);
    } catch (err : any) {
      this.$bvModal.msgBoxOk(err.response.data);
    } finally {
      this.tableBusy = false;
    }
  }

  async loadGrnSeries() {
    const result = camelcaseKeys(await getAvailableDocumentSeries(
      constants.GRN,
      this.grnSeriesYear,
      this.grnPageParams.pageNumber,
      this.generalPerPage,
    ));

    this.grnSeries = result.items;
    this.grnAvailableYears = result.seriesYearsItems.map((e: any) => ({ text: e, value: e }));
    this.grnAvailableYears.unshift({ text: 'Toți anii', value: null });
    this.grnPageParams.totalRows = result.totalRows;
  }

  async loadMrnSeries() {
    const result = camelcaseKeys(await getAvailableDocumentSeries(
      constants.MRN,
      this.mrnSeriesYear,
      this.mrnPageParams.pageNumber,
      this.generalPerPage,
    ));

    this.mrnSeries = result.items;
    this.mrnAvailableYears = result.seriesYearsItems.map((e: any) => ({ text: e, value: e }));
    this.mrnAvailableYears.unshift({ text: 'Toți anii', value: null });
    this.mrnPageParams.totalRows = result.totalRows;
  }

  async loadBcoSeries() {
    const result = camelcaseKeys(await getAvailableDocumentSeries(
      constants.BCO,
      this.bcoSeriesYear,
      this.bcoPageParams.pageNumber,
      this.generalPerPage,
    ));

    this.bcoSeries = result.items;
    this.bcoAvailableYears = result.seriesYearsItems.map((e: any) => ({ text: e, value: e }));
    this.bcoAvailableYears.unshift({ text: 'Toți anii', value: null });
    this.bcoPageParams.totalRows = result.totalRows;
  }

  async loadBtoSeries() {
    const result = camelcaseKeys(await getAvailableDocumentSeries(
      constants.BTO,
      this.btoSeriesYear,
      this.btoPageParams.pageNumber,
      this.generalPerPage,
    ));

    this.btoSeries = result.items;
    this.btoAvailableYears = result.seriesYearsItems.map((e: any) => ({ text: e, value: e }));
    this.btoAvailableYears.unshift({ text: 'Toți anii', value: null });
    this.btoPageParams.totalRows = result.totalRows;
  }

  resetPageParams(type) {
    if (type === this.documentConstants.GRN) {
      this.grnPageParams = { pageNumber: 1, totalRows: 0 };
    } else if (type === this.documentConstants.MRN) {
      this.mrnPageParams = { pageNumber: 1, totalRows: 0 };
    } else if (type === this.documentConstants.BCO) {
      this.bcoPageParams = { pageNumber: 1, totalRows: 0 };
    } else if (type === this.documentConstants.BTO) {
      this.btoPageParams = { pageNumber: 1, totalRows: 0 };
    }
  }

  resetSeriesFilters(type) {
    if (type === this.documentConstants.GRN) {
      this.grnSeriesYear = null;
    } else if (type === this.documentConstants.MRN) {
      this.mrnSeriesYear = null;
    } else if (type === this.documentConstants.BCO) {
      this.bcoSeriesYear = null;
    } else if (type === this.documentConstants.BTO) {
      this.btoSeriesYear = null;
    }
  }

  async initialize() {
    await this.loadGridPage();
  }

  created() {
    this.initialize();
  }
}
